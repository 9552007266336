import React from "react";
import {} from "react-bootstrap";

function Welcome() {
  return (
    <div>
      A big brown fox jumped over the lazy dog. A big brown fox jumped over the
      lazy dog. a big brown fox jumped over the lazy dog
    </div>
  );
}

export default Welcome;
